// 日期筛选
export const pickerOptions = {
  shortcuts: [
    {
      text: "最近一周",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "最近一个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "最近三个月",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit("pick", [start, end]);
      },
    },
    {
      text: "最近一年",
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
        picker.$emit("pick", [start, end]);
      },
    },
  ],
};

// 菜单等级
export const menuLevelObj = {
  1: "一级",
  2: "二级",
  3: "按钮",
};
// 团建状态
export const partyStatusObj = {
  1: "待生效",
  2: "未开始",
  3: "进行中",
  4: "已取消",
  5: "已结算",
};
// 团单状态
export const partyOrderStatusObj = {
  1: "待支付",
  2: "已支付",
  9: "已取消",
  10: "支付超时",
};
export const menuLevelColor = {
  1: "#FF0000",
  2: "#E6A23C",
  3: "#1E9FFF",
};
// 性别
export const sexObj = {
  0: "男",
  1: "女",
};
// 管理员
export const isAdminObj = {
  0: "普通用户",
  1: "商户管理员",
};
export const isAdminColor = {
  0: "#909399",
  1: "#1E9FFF",
};
// 商户状态
export const merchantStatusObj = {
  0: "停用",
  1: "启用",
};
export const merchantStatusColor = {
  0: "#FF0000",
  1: "#009688",
};
export const bindStatusObj = {
  0: "未绑定",
  1: "已绑定",
};

// 店铺状态
export const shopStatusObj = {
  0: "停用",
  1: "启用",
};
// 开门条件
export const openDoorCondition = {
  0: "无限制",
  1: "当天存在有效订单",
  2: "存在有效订单",
};
export const shopStatusColor = {
  0: "#FF0000",
  1: "#009688",
};
// 店铺状态
export const shopTypeObj = {
  1: "直营店",
  2: "加盟店",
  3: "联营店",
};
// 店铺状态
export const stopUseStatusObj = {
  0: "结束",
  1: "进行",
};
// 店铺状态
export const stopUseStatusColor = {
  0: "#909399",
  1: "#009688",
};
// 订单状态
export const orderStatusObj = {
  1: "待支付",
  2: "未开始",
  3: "进行中",
  4: "已完成",
  9: "已取消",
};
// 结算状态
export const settleObj = {
  1: "待结算",
  2: "已结算",
  3: "待退款",
  4: "已退款",
};
export const orderStatusColor = {
  1: "#FF0000",
  2: "#E6A23C",
  3: "#009688",
  4: "#909399",
  9: "#909399",
};
// 团购卡券状态
export const saleStatusObj = {
  1: "未开始",
  2: "售卖中",
  3: "已结束",
};

// 团购卡券状态
export const onlineStatusObj = {
  1: "在线",
  2: "下线",
  3: "封禁",
};
// 团购卡券状态
export const onlineOrderStatusObj = {
  2: "已兑换",
  6: "已退款",
};
// 卡券类型
export const cardTypeObj = {
  1: "次卡",
  2: "时长卡",
  3: "代金券",
  4: "团建券",
  5: "自定义券",
};
// 卡券类型(平台)
export const plateCardTypeObj = {
  1: "次数卡",
  // 2: "时长卡",
  3: "代金券",
  // 4: "团建券",
  5: "自定义券",
};
// 渠道码使用情况
export const useStatusObj = {
  1: "可兑换",
  2: "兑换完成",
  3: "已失效",
};
// 渠道码状态
export const codeStatusObj = {
  1: "启用",
  0: "停用",
};
// 卡券状态
export const cardStatusObj = {
  0: "已下架",
  1: "售卖中",
};
export const cardStatusColor = {
  0: "#909399",
  1: "#009688",
};
// 卡券操作类型
export const operateTypeObj = {
  1: "上架",
  2: "下架",
  3: "排序",
  4: "发放卡券",
};
// 卡券背景
export const cardBgArr = [
  {
    value: "0",
    url: require("../assets/images/card/0.jpg"),
  },
  {
    value: "1",
    url: require("../assets/images/card/1.png"),
  },
  {
    value: "2",
    url: require("../assets/images/card/2.png"),
  },
  {
    value: "3",
    url: require("../assets/images/card/3.png"),
  },
  {
    value: "4",
    url: require("../assets/images/card/4.png"),
  },
  {
    value: "5",
    url: require("../assets/images/card/5.png"),
  },
  {
    value: "6",
    url: require("../assets/images/card/6.png"),
  },
  {
    value: "7",
    url: require("../assets/images/card/7.png"),
  },
  {
    value: "8",
    url: require("../assets/images/card/8.png"),
  },
  {
    value: "9",
    url: require("../assets/images/card/9.png"),
  },
  {
    value: "10",
    url: require("../assets/images/card/10.png"),
  },
  {
    value: "11",
    url: require("../assets/images/card/11.png"),
  },
  {
    value: "12",
    url: require("../assets/images/card/12.png"),
  },
  {
    value: "13",
    url: require("../assets/images/card/13.png"),
  },
  {
    value: "14",
    url: require("../assets/images/card/14.png"),
  },
];
// 适用时段
export const cardDateArr = [
  {
    value: "1",
    label: "不限制",
  },
  {
    value: "2",
    label: "周一至周五",
  },
  {
    value: "3",
    label: "工作日",
  },
  {
    value: "4",
    label: "双休日",
  },
  {
    value: "5",
    label: "法定节假日",
  },
  {
    value: "6",
    label: "双休日 + 法定节假日",
  },
  {
    value: "7",
    label: "其他",
  },
];
export const cardWeekArr = [
  {
    value: "2",
    label: "星期一",
  },
  {
    value: "3",
    label: "星期二",
  },
  {
    value: "4",
    label: "星期三",
  },
  {
    value: "5",
    label: "星期四",
  },
  {
    value: "6",
    label: "星期五",
  },
  {
    value: "7",
    label: "星期六",
  },
  {
    value: "1",
    label: "星期日",
  },
];
export const cardTipsObj = {
  1: "*该卡券周一、二、三、四、五、六、日，",
  2: "*该卡券周一、二、三、四、五，",
  3: "*该卡券周一、二、三、四、五、补班（不包含法定节假日），",
  4: "*该卡券周六、日（不包含补班），",
  5: "*该卡券法定节假日，",
  6: "*该卡券周六、日、法定节假日（不包含补班），",
};
export const continuousMaxHourObj = [
  {
    value: 0,
    label: "不限制",
  },
  {
    value: 0.5,
    label: "0.5小时",
  },
  {
    value: 1,
    label: "1小时",
  },
  {
    value: 2,
    label: "2小时",
  },
  {
    value: 3,
    label: "3小时",
  },
  {
    value: 4,
    label: "4小时",
  },
  {
    value: 5,
    label: "5小时",
  },
  {
    value: 6,
    label: "6小时",
  },
  {
    value: 7,
    label: "7小时",
  },
  {
    value: 8,
    label: "8小时",
  },
  {
    value: 9,
    label: "9小时",
  },
  {
    value: 10,
    label: "10小时",
  },
  {
    value: 11,
    label: "11小时",
  },
  {
    value: 12,
    label: "12小时",
  },
  {
    value: 13,
    label: "13小时",
  },
  {
    value: 14,
    label: "14小时",
  },
  {
    value: 15,
    label: "15小时",
  },
  {
    value: 16,
    label: "16小时",
  },
  {
    value: 17,
    label: "17小时",
  },
  {
    value: 18,
    label: "18小时",
  },
  {
    value: 19,
    label: "19小时",
  },
  {
    value: 20,
    label: "20小时",
  },
  {
    value: 21,
    label: "21小时",
  },
  {
    value: 22,
    label: "22小时",
  },
  {
    value: 23,
    label: "23小时",
  },
  {
    value: 24,
    label: "24小时",
  },
];

// 卡券获取方式
export const paymentTypeObj = {
  1: "微信支付",
  2: "抖音兑换",
  3: "美团兑换",
  4: "商家直接发放",
  7: "本金支付",
  44: "平台手动发放",
  54: "会员权益领取",
  34: "注册奖励",
  24: "拉新奖励",
  14: "入群奖励",
};
// 卡券获取方式(平台)
export const platePaymentTypeObj = {
  // 1: "微信支付",
  // 2: "抖音兑换",
  // 3: "美团兑换",
  // 4: "商家直接发放",
  // 7: "本金支付",
  34: "注册奖励",
  24: "拉新奖励",
  14: "入群奖励",
  54: "会员权益领取",
  44: "平台手动发放",
  10: "积分兑换",
};
export const payTypeObj = {
  5: "余额支付",
  6: "微信支付",
};
// 卡券订单状态
export const cardOrderStatusObj = {
  0: "未使用",
  1: "使用中",
  2: "已禁用",
  3: "已使用",
  4: "已过期",
};
export const cardOrderStatusColor = {
  0: "#E6A23C",
  1: "#6EA83A",
  2: "#FF0000",
  3: "#909399",
  4: "#909399",
};
// 卡券订单状态
export const noticeStatusObj = {
  1: "启用",
  2: "停用",
};
export const noticeStatusColor = {
  1: "#009688",
  2: "#FF0000",
};
// 活动类型
export const activityTypeObj = {
  1: "不动作",
  2: "卡券套餐",
  3: "公众号",
};
// 保洁启用禁用状态颜色
export const peopleStatusObj = {
  1: "启用",
  2: "停用",
};
export const peopleStatusColor = {
  1: "#009688",
  2: "#FF0000",
};
export const cleanStatusObj = {
  1: "待保洁",
  2: "保洁中",
  3: "预保洁",
  4: "已完成",
};
export const cleanStatusColor = {
  1: "#009688",
  2: "#009688",
  3: "#909399",
  4: "#909399",
};
export const cleanOperateTypeObj = {
  0: "新建",
  1: "开始",
  2: "延长",
  3: "结束",
};
// 用户管理
export const userMenuObj = [
  {
    title: "基本信息",
    path: "/user/userInfo/baseInfo",
  },
  {
    title: "位置订单记录",
    path: "/user/userInfo/userOrderList",
  },
  {
    title: "用户卡券",
    path: "/user/userInfo/userCardList",
  },
  {
    title: "商品订单",
    path: "/user/userInfo/goodList",
  },
  {
    title: "余额账户明细",
    path: "/user/userInfo/balanceInfo",
  },
  {
    title: "门店套餐充值明细",
    path: "/user/userInfo/charge",
  },
  {
    title: "会员等级概况",
    path: "/user/userInfo/memberLevel",
  },
  {
    title: "成长值明细",
    path: "/user/userInfo/growthValue",
  },
  {
    title: "积分明细",
    path: "/user/userInfo/point",
  },
];
// 设备管理
export const deviceMenuObj = [
  {
    title: "门禁",
    path: "/device/deviceList/doorList",
  },
  {
    title: "电源",
    path: "/device/deviceList/powerList",
  },
  {
    title: "喇叭",
    path: "/device/deviceList/playerList",
  },
  {
    title: "售卖柜",
    path: "/device/deviceList/cabinetList",
  },
];
// 推销业绩
export const salesObj = [
  // {
  //   title: "商品业绩",
  //   path: "/serve/salesList/goodsSalesList",
  // },
  {
    title: "推销商品业绩",
    path: "/serve/salesList/recommendGoodsList",
  },
  {
    title: "充值业绩",
    path: "/serve/salesList/packageSalesList",
  },
];
// 团购管理
export const tuanGouShop = [
  {
    title: "北极星",
    path: "/group/shopList/dianPingList",
  },
  {
    title: "抖音",
    path: "/group/shopList/douYinList",
  },
  {
    title: "美团",
    path: "/group/shopList/meiTuanList",
  },
];
// 团购管理
export const tuanGouCard = [
  {
    title: "北极星",
    path: "/group/cardList/dianPingList",
  },
  {
    title: "抖音",
    path: "/group/cardList/douYinList",
  },
  {
    title: "美团",
    path: "/group/cardList/meiTuanList",
  },
];
// 小程序配置
export const miniMenuObj = [
  {
    title: "门店轮播图",
    path: "/shop/mini/swipe",
  },
  {
    title: "主推套餐配置",
    path: "/shop/mini/package",
  },
  {
    title: "WiFi设置",
    path: "/shop/mini/wifi",
  },
  {
    title: "常见问题",
    path: "/shop/mini/question",
  },
  {
    title: "订单详情文案",
    path: "/shop/mini/order",
  },
  {
    title: "分享配置",
    path: "/shop/mini/share",
  },
];

// 设备状态
export const deviceStatusObj = {
  0: "离线",
  1: "在线",
};

export const deviceStatusColor = {
  0: "#FF0000",
  1: "#009688",
};

export const radioObj = [
  {
    label: "欢迎光临",
    value: 1,
  },
  {
    label: "更换房间",
    value: 2,
  },
  {
    label: "仅剩10分钟",
    value: 3,
  },
  {
    label: "仅剩3分钟",
    value: 4,
  },
  {
    label: "下次光临",
    value: 5,
  },
];

export const volumeObj = [
  {
    label: "音量0",
    value: 0,
  },
  {
    label: "音量5",
    value: 5,
  },
  {
    label: "音量10",
    value: 10,
  },
  {
    label: "音量15",
    value: 15,
  },
  {
    label: "音量20",
    value: 20,
  },
  {
    label: "音量25",
    value: 25,
  },
  {
    label: "音量30",
    value: 30,
  },
  {
    label: "音量35",
    value: 35,
  },
  {
    label: "音量40",
    value: 40,
  },
  {
    label: "音量45",
    value: 45,
  },
  {
    label: "音量50",
    value: 50,
  },
  {
    label: "音量55",
    value: 55,
  },
  {
    label: "音量60",
    value: 60,
  },
  {
    label: "音量65",
    value: 65,
  },
  {
    label: "音量70",
    value: 70,
  },
  {
    label: "音量75",
    value: 75,
  },
  {
    label: "音量80",
    value: 80,
  },
  {
    label: "音量85",
    value: 85,
  },
  {
    label: "音量90",
    value: 90,
  },
  {
    label: "音量95",
    value: 95,
  },
  {
    label: "音量100",
    value: 100,
  },
];

// 服务订单状态
export const serveOrderStatusObj = {
  1: "服务中",
  2: "待服务",
  3: "预服务",
  4: "已完成",
  5: "未完成",
  6: "已取消",
};

export const serveOrderStatusColor = {
  1: "#009688",
  2: "#009688",
  3: "#E6A23C",
  4: "#909399",
  5: "#FF0000",
  6: "#909399",
};

export const serveOperateTypeObj = {
  0: "新建",
  1: "开始",
  2: "取消",
  3: "结束",
};

// 商品状态
export const goodsStatusObj = {
  0: "已下架",
  1: "售卖中",
  2: "已售罄",
};

// 派送订单状态
export const serviceStatusObj = {
  0: "待派送",
  1: "派送中",
  4: "已完成",
  6: "已取消",
};

// 订单支付方式
export const paymentOrderTypeObj = {
  1: "微信支付",
  // 2: "抖音兑换",
  // 3: "美团兑换",
  // 4: "系统发放",
  7: "本金支付",
  8: "赠金支付",
  5: "卡券抵扣",
  6: "代客下单",
  10: "积分支付",
};
// 订单类型
export const orderTypeObj = {
  1: "位置订单",
  2: "购买卡券",
  3: "预定商品",
};
// 首页时段切换对象
export const timeFrameObj = {
  1: "全部",
  2: "今日",
  3: "昨日",
  4: "近七天",
  5: "本月",
  6: "上月",
};

// 首页销售时段切换对象
export const timeSellObj = {
  1: "全部",
  // 2: "今日",
  // 3: "昨日",
  4: "近七天",
  7: "近30天",
  5: "本月",
  6: "上月",
};

// 首页财务统计上面
export const homeFinanceTopObj = [
  {
    title: "微信净收入",
    name: "wxCount",
    refundName: "wxRefund",
    icon: "iconfont icon-icon_vx",
    count: "-",
    refund: "-",
    type: "4",
  },
  {
    title: "线下净收入",
    name: "cashCount",
    refundName: "cashRefund",
    icon: "iconfont icon-icon_sr",
    count: "-",
    refund: "-",
    type: "2",
  },
  {
    title: "团购净收入",
    name: "groupCount",
    refundName: "groupRefund",
    icon: "iconfont icon-icon_tg",
    count: "-",
    refund: "-",
    type: "1",
  },
  {
    title: "本金净收入",
    name: "balanceCount",
    refundName: "balanceRefund",
    icon: "iconfont icon-icon_bj",
    count: "-",
    refund: "-",
    type: "5",
  },
  {
    title: "赠金净收入",
    name: "giveCount",
    refundName: "giveRefund",
    icon: "iconfont icon-icon_zj",
    count: "-",
    refund: "-",
    type: "9",
  },
];

// 首页财务统计下面
export const homeFinanceBottomObj = [
  {
    title: "位置下单净收入",
    name: "roomCount",
    refundName: "roomRefund",
    icon: "iconfont icon-icon_dd",
    count: "-",
    refund: "-",
  },
  {
    title: "卡券净收入",
    name: "cardCount",
    refundName: "cardRefund",
    icon: "iconfont icon-icon_kq",
    count: "-",
    refund: "-",
  },
  {
    title: "商品净收入",
    name: "goodsCount",
    refundName: "goodsRefund",
    icon: "iconfont icon-icon_sp",
    count: "-",
    refund: "-",
  },
];

// 首页用户统计
export const homeUserObj = [
  {
    title: "今日新增",
    name: "today",
    count: "-",
  },
  {
    title: "近7日新增",
    name: "last7Days",
    count: "-",
  },
  {
    title: "近30日新增",
    name: "last30Days",
    count: "-",
  },
  {
    title: "今日活跃",
    name: "updateToday",
    count: "-",
  },
  {
    title: "近7日活跃",
    name: "updateLast7Days",
    count: "-",
  },
  {
    title: "近30日活跃",
    name: "updateLast30Days",
    count: "-",
  },
];
export const echartDateType = {
  2: "按周",
  3: "按月",
};
export const payChannelObj = {
  1: "团购",
  2: "线下",
  4: "微信",
  5: "本金",
  9: "赠金",
};

export const businessTypeObj = {
  1: "卡券订单",
  2: "售卖柜订单",
  3: "派送订单",
  9: "位置订单",
  12: "团建团单",
};
export const shopObj = {
  2: "售卖柜订单",
  3: "派送订单",
};

// 到店按钮/文字
export const startBtnTitle = {
  1: "到店开门",
  2: "到店开台",
  5: "立即使用",
};

// 离店按钮/文字
export const leaveBtnTitle = {
  1: "离店",
  2: "闭台",
  5: "结束使用",
};

// 离店按钮/文字
export const serviceTemplate = {
  1: "包厢类",
  2: "台球类",
  3: "餐桌类",
  4: "仓储类",
  5: "工位类",
};
// 积分流水明细
export const pointRecordType = {
  2: "售货柜订单",
  3: "派送订单",
  5: "活动充值赠送",
  9: "位置订单",
  14: "兑换卡券订单",
  15: "兑换商品订单",
  16: "积分过期",
  17: "每日签到",
  18: "会员达标",
};
// 明细分类
export const businessStatusObj = {
  1: "卡券订单",
  2: "售货柜订单",
  3: "派送订单",
  4: "余额充值",
  5: "活动充值",
  6: "门店代充",
  7: "平台代充",
  9: "位置订单",
  10: "赠金过期",
};
// 打开类型
export const openType = {
  0: "不动作",
  1: "卡券",
  2: "充值",
  3: "公众号",
};
export const popupStatusObj = {
  0: "停用",
  1: "启用",
};
// 连续时间类型
export const cardPricingTypeObj = {
  1: "按小时",
  2: "按天",
  3: "按月",
};
// 虚拟券包状态
export const virtualCouponStatusObj = {
  0: "未开始",
  1: "生效中",
  2: "已过期",
};
// 代充状态
export const rechargeStatus = {
  0: "未支付",
  1: "已支付",
  2: "已取消",
};
// 推销商品订单状态
export const goodsOrderStatus = {
  1: "有效订单",
  2: "退款订单",
};
// 推销商品订单状态
export const shopRange = {
  1: "推销业绩（本门店）",
  2: "推销业绩（所有门店）",
};
// 部门状态
export const departmentStatus = {
  0: "正常",
  1: "停用",
};
// 审批流程类型
export const flowType = {
  1: "常规审核",
};
// 流程分组
export const flowGroup = {
  1: "人事类",
  2: "财务类",
  3: "招商类",
  4: "工程类",
  5: "其他类",
};
// 是否
export const yesOrNo = {
  1: "是",
  2: "否",
};
// 条件类型
export const conditionType = {
  1: "文本",
  2: "数字",
  3: "金额",
  4: "日期",
  5: "单选",
  6: "多选",
  7: "图片",
  8: "附件",
};
// 工单类型
export const workOrderType = {
  1: "易耗品调拨",
  2: "易耗品领用",
  3: "易耗品报废消耗",
  4: "易耗品活动消耗",
  5: "易耗品其他消耗",
  6: "易耗品消耗回退",
};
// 工单类型
export const WOT = {
  1: "请假申请",
  2: "总部付款申请",
  3: "设计预算工单",
  4: "工程施工工单",
  5: "设计延期工单",
  6: "预算延期工单",
  7: "施工延期工单",
  8: "项目撤销工单",
  9: "项目付款工单",
  10: "账单扣款工单",
  11: "账单关闭工单",
  12: "账单补付工单",
};
// 参数类型
export const paramType = {
  1: "输入框",
  2: "选择器",
  3: "日期",
  4: "上传",
};
// 参数属性
export const inputTypeOpt = {
  1: {
    1: "整数数字",
    2: "浮点数字",
    3: "文本 ",
    4: "文本域",
  },
  2: {
    5: "单选",
    6: "多选",
  },
  3: {
    7: "日期",
  },
  4: {
    8: "上传",
  },
};
export const inputTypeObj = {
  1: "整数",
  2: "浮点",
  3: "文本",
  4: "文本域",
  5: "单选",
  6: "多选",
  7: "日期",
  8: "上传",
};
// 数据源
export const dataSource = {
  1: "固定数据源",
  2: "动态数据源",
};
// 文件类型
export const fileType = {
  1: "图片",
  2: "文件",
  3: "不限",
};
// 审批状态
export const auditStatus = {
  1: "审批中",
  2: "审批通过",
  3: "审批拒绝",
  4: "已撤销",
};
// 日期范围
export const timeRangeObj = {
  1: "本月",
  2: "本年",
  3: "全部",
};
// 审批类型
export const processTypeObj = {
  1: "合同签约",
  3: "合同退租",
  4: "合同作废",
  5: "工单签发",
  7: "账单延期",
  12: "工单验收",
  14: "抬头变更",
  15: "积分赠送",
  16: "积分补扣",
  17: "预付费扣款",
  18: "预付费补偿",
  30: "工程预算联系单",
  31: "工程改造工单申请",
  32: "工单延期申请",
  33: "账单拆分",
  55: "预付费充值",
  58: "房源变更",
  59: "房源拆合",
  60: "续签转退租",
  70: "账单撤回",
  80: "预定签约",
  81: "预定作废",
  90: "工程相关审批",
  99: "房源批量定价",
  100: "资产领用",
  101: "资产调拨",
  102: "资产报废",
  103: "结佣审批",
  104: "付佣审批",
  105: "账单调整",
};
export const approveStatusObj = {
  "-2": "未发起",
  0: "待审批",
  1: "审批通过",
  2: "审批撤回",
  3: "办理中",
  "-1": "审批拒绝",
};
// 审批状态颜色
export const approveStatusColor = {
  "-2": "#909399",
  0: "#FFB800",
  1: "#009688",
  2: "#1E9FFF",
  3: "#9481FF",
  "-1": "#FF5722",
};
export const bool = {
  true: "是",
  false: "否",
};
