/**
 * 时间格式转换器
 * @param {*} time 时间撮
 * @param {*} format 格式
 * @returns 
 */
// 大于0且最多两位小数
const floatTowReg = (rule, value, callback) => {
  let reg = /^(?:[1-9]\d*(?:\.\d{1,2})?|0\.\d{1,2})$/
  if (value && reg.test(value) === false) {
    callback(new Error('请填写大于0且最多两位小数'))
  } else {
    callback()
  }
};
// 大于0且最多两位小数
const floatTowRegObj = {
  validator: floatTowReg,
  trigger: 'blur',
}

// 大于等于0且最多一位小数
const floatOneReg = (rule, value, callback) => {
  let reg = /^(?:[1-9]\d*(?:\.\d{1})?|0(?:\.\d{1})?)$/
  if (value && reg.test(value) === false) {
    callback(new Error('请填写大于等于0且最多一位小数'))
  } else {
    callback()
  }
};
// 大于等于0且最多一位小数
const floatOneRegObj = {
  validator: floatOneReg,
  trigger: 'blur',
}

// 大于等于0且最多两位小数
const floatTowZeroReg = (rule, value, callback) => {
  let reg = /^(?:[1-9]\d*(?:\.\d{1,2})?|0(?:\.\d{1,2})?)$/
  if (value && reg.test(value) === false) {
    callback(new Error('请填写大于等于0且最多两位小数'))
  } else {
    callback()
  }
};
// 大于等于0且最多两位小数
const floatTowZeroRegObj = {
  validator: floatTowZeroReg,
  trigger: 'blur',
}

// 大于0的整数
const intValidatorReg = (rule, value, callback) => {
  let reg = /^[1-9]\d*$/
  if (value && reg.test(value) === false) {
    callback(new Error('请填写大于0的整数'))
  } else {
    callback()
  }
};
// 大于0的整数
const intValidatorRegObj = {
  validator: intValidatorReg,
  trigger: 'blur',
}

// 大于等于0的整数
const intValidatorZeroReg = (rule, value, callback) => {
  let reg = /^[0-9]\d*$/
  if (value && reg.test(value) === false) {
    callback(new Error('请填写大于等于0的整数'))
  } else {
    callback()
  }
};
// 大于等于0的整数
const intValidatorZeroRegObj = {
  validator: intValidatorZeroReg,
  trigger: 'blur',
}

// 百分比
const percentageReg = (rule, value, callback) => {
  let reg = /^(?:100(?:\.0)?|[0-9]{1,2}(?:\.[0-9]{1,2})?)$/
  if (value && reg.test(value) === false) {
    callback(new Error('请填写0至100且最多两位小数'))
  } else {
    callback()
  }
};
// 百分比
const percentageRegObj = {
  validator: percentageReg,
  trigger: 'blur',
}

// 不含斜杠
const slashValidator = (rule, value, callback) => {
  let reg = /\//
  if (value && reg.test(value) === true) {
    callback(new Error('不能填写包含/的字符'))
  } else {
    callback()
  }
};
// 不含斜杠
const slashValidatorObj = {
  validator: slashValidator,
  trigger: 'blur',
}

// -100至100的整数
const validateNumber100 = (rule, value, callback) => {
  let reg = /^(-?100|-?[1-9]?\d)$/;
  if (value && reg.test(value) === false) {
    callback(new Error('请填写-100至100的整数'))
  } else {
    callback()
  }
};
// 不含斜杠
const validateNumber100Obj = {
  validator: validateNumber100,
  trigger: 'blur',
}
// 时间转换格式列表用
export function changeDate(time, format) {
  if (time == null || time == "") {
      return "-";
  }
  switch (Number(format)) {
      case 1:
          format = 'yyyy-MM-dd HH:mm:ss';
          break;
      case 2:
          format = 'yyyy-MM';
          break;
      case 3:
          format = 'yyyy-MM-dd HH:mm';
          break;
      default:
          format = 'yyyy-MM-dd';
  }
  var d = new Date(time);
  var o = {
      "q+": Math.floor((d.getMonth() + 3) / 3), //季度
      "M+": d.getMonth() + 1, //月
      "d+": d.getDate(), //日
      "H+": d.getHours(), //时
      "m+": d.getMinutes(), //分
      "s+": d.getSeconds(), //秒
      "S": d.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(format))
      format = format.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var i in o)
      if (new RegExp("(" + i + ")").test(format))
          format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[i]) : (("00" + o[i]).substr(("" + o[i]).length)));
  return format;
}
export function formatterDate(time, format) {
  if (time == null || time == "") {
    return "暂无日期";
  }
  switch (Number(format)) {
    case 1:
      format = 'yyyy-MM-dd HH:mm:ss';
      break;
    case 2:
      format = 'yyyy/MM/dd';
      break;
    case 3:
      format = 'yyyy/MM/dd HH:mm:ss';
      break;
    default:
      format = 'yyyy-MM-dd';
  }
  let d = new Date(time);
  let o = {
    "q+": Math.floor((d.getMonth() + 3) / 3), //季度
    "M+": d.getMonth() + 1, //月
    "d+": d.getDate(), //日
    "H+": d.getHours(), //时
    "m+": d.getMinutes(), //分
    "s+": d.getSeconds(), //秒
    "S": d.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(format))
    format = format.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let i in o)
    if (new RegExp("(" + i + ")").test(format))
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[i]) : (("00" + o[i]).substr(("" + o[i]).length)));
  return format;
}

//对象转选项
export function ObjToOpt(obj) {
  let options = []
  for (let i in obj) {
    options = [...options, {
      value: Number(i),
      label: obj[i]
    }]
  }
  return options
};
// 选择天
export function selectDay(length, interval) {
  let arr = [];
  let obj = {
    label: "",
    value: "",
  };
  for (let i = 1; i <= length; i++) {
    obj = {
      label: interval * i + "天",
      value:i,
    };
    arr.push(obj);
  }
  return arr;
}
// 选择月
export function selectMonth(length, interval) {
  let arr = [];
  let obj = {
    label: "",
    value: "",
  };
  for (let i = 1; i <= length; i++) {
    obj = {
      label: interval * i + "个月",
      value:i,
    };
    arr.push(obj);
  }
  return arr;
}
// 获取路径"?"后面的参数
export function getUrlParams() {
  var url = location.search;
  var params = new Object();
  if (url.indexOf("?") != -1) {
      url = url.slice(1).split("&");
      for (var i in url) {
          params[url[i].split("=")[0]] = decodeURI(url[i].split("=")[1]);
      }
  }
  return params;
};
// 【转换】验证规则
export function workorderRules(item) {
  let obj = {
    // 整数
    1: [{
      required: item.required,
      message: item.placeholder,
      trigger: 'blur',
    },
    {
      min: item.minlength,
      max: item.maxlength,
      message: `请填写长度在${item.minlength}到${item.maxlength}个字符`,
      trigger: 'blur'
    }, intValidatorZeroRegObj],
    // 浮点
    2: [{
      required: item.required,
      message: item.placeholder,
      trigger: 'blur',
    },
    {
      min: item.minlength,
      max: item.maxlength,
      message: `请填写长度在${item.minlength}到${item.maxlength}个字符`,
      trigger: 'blur'
    }, floatTowZeroRegObj],
    // 文本
    3: [{
      required: item.required,
      message: item.placeholder,
      trigger: 'blur',
    },
    {
      min: item.minlength,
      max: item.maxlength,
      message: `请填写长度在${item.minlength}到${item.maxlength}个字符`,
      trigger: 'blur'
    },
    ],
    // 文本域
    4: [{
      required: item.required,
      message: item.placeholder,
      trigger: 'blur',
    },],
    // 单选
    5: [{
      required: item.required,
      message: item.placeholder,
      trigger: 'blur',
    },],
    // 多选
    6: [{
      required: item.required,
      message: item.placeholder,
      trigger: 'blur',
    },],
    // 日期
    7: [{
      required: item.required,
      message: item.placeholder,
      trigger: 'blur',
    },],
    // 上传
    8: [{
      required: item.required,
      message: "请上传文件",
      trigger: 'blur',
    },],
  }
  return obj[item.inputType]
};
export function changeHttps(arr) {
  if (arr.length > 0) {
    for (let i of arr) {
      if (i.fileUrl) {
        i.fileUrl = i.fileUrl.replace("http://", "https://")
      }
    }
    return arr
  }
};

// 【转换】文件列表处理
export function changeFileList(list) {
  function readFileType(fileName) {
    fileName = fileName.split('.')
    let type = fileName[fileName.length - 1]
    if (type == "jpg" || type == "jpeg" || type == "gif" || type == "png" || type == "bmp") {
      return true
    } else {
      return false
    }
  };
  let obj = {
    fileList: [],
    imgList: [],
    imgViewList: []
  };
  if (list) {
    for (let i of list) {
      if (readFileType(i.fileName)) {
        obj.imgList.push(i);
        obj.imgViewList.push(i.fileUrl);
      } else {
        obj.fileList.push(i);
      }
    };
  };
  return obj
};